<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                海通证券携手非凸科技，共谋行业未来和生态合作
              </p>
              <div class="fst-italic mb-2">2024年1月29日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >海通证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/73/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，海通证券红宝石路证券营业部与非凸科技携手在上海举办了“科技而生·赋能量化”系列交流活动。此次活动汇集了管理人、资方，共同探讨了量化行业发展，未来策略展望及合作方向等热点话题，为科技赋能下的量化领域提供了深入见解。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/73/02-海通.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为行业金融科技创新的先行者和探索者，海通证券一直坚持“科技引领”的发展战略，持续推进金融科技应用创新，不断夯实数字化基础，提升数字化能力，为推动公司数字化转型和行业高质量发展而不懈努力。同时，海通证券红宝石路证券营业部作为海通证券倾力打造的创新型营业部，致力于为财富管理、企业经营、家族治理等全方位需求提供一站式综合解决方案。会上，海通证券红宝石路证券营业部副总经理徐耕岩详细介绍了营业部创新特色服务及红宝石私享FOF系列。他表示，此次活动不仅是业务交流与合作的机会，更是我们共同迎接未来挑战的契机。红宝石路证券营业部将作为链接高净值客户财富管理的需求以及私募管理人优质策略的平台，以更高效、更全面的方式整合资金端和资产端，也将持续探索私募合作新模式，实现可持续发展与生态伙伴的合作共赢。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/73/03-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技机构业务经理周升为在场观众分享了智能交易解决方案及未来业务愿景。近年来，非凸科技融合先进的量化模型，结合大数据、机器学习、深度学习等新兴技术，不断升级迭代算法能力，持续完善各类交易工具，致力于为客户提供全面的智能算法生态。同时，也在积极投入算法设施建设和AI智能人才，推进智能化和场景化业务的创新与落地。非凸科技也希望通过与行业联动交流，共同探索智能交易的更多可能性，将卓越的算法服务体验带给亿万投资者。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/73/04-交流.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在交流环节，管理人和资方积极参与，分享了各自独特的量化策略及业务模式，讨论了未来投资方向及策略展望，展现了对市场变化的敏锐洞察。此次充满活力的交流与分享，不仅是信息的碰撞，更是对行业发展的深刻探讨，也为未来合作打下了坚实的基础。
                  </p>
                  <p>
                    未来，非凸科技将充分发挥自身技术优势和深厚行业经验，积极参与量化生态建设，为金融行业高质量发展提供全面支持。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News73",
};
</script>

<style></style>
